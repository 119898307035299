import React from 'react'
import DirectToBank from "../assets/direct-to-bank.svg"
import TradeAnywhere from "../assets/trade-anywhere.svg";
import LowTradingFee from "../assets/low-trading-fee.svg"
import Container from './Container';
import { CtaButtons } from './CtaButtons';
import { motion } from "framer-motion"




const Features = () => {
    const listParentVaraint = {
        "hide": {
            opacity: 0
        },
        "show": {
            opacity: 1,
            transition: {
                duration: 2,

                staggerChildren: 1
            }
        },

    }
    const listChildrenVariant = {
        "hide": {
            y: window.innerWidth > 500 ? 100 : 0,
            opacity: 0,
            x: window.innerWidth > 500 ? 0 : -100
        },
        "show": {
            y: 0,
            x: 0,
            opacity: 1,
            transition: {
                duration: 1,
                staggerChildren: 0.5
            }
        },

    }
    return (
        <div className='w-full '>
            <Container className="py-10">
                <p className='my-10 text-[25px] md:text-[30px] font-black '>Get the best <span className='text-blue'>features</span> at your finger tips</p>
                <div>
                    <motion.ul variants={listParentVaraint}
                        initial="hide" whileInView="show"
                        className='flex md:flex-row flex-col justify-between gap-[60px]'>
                        <motion.li
                            key="d-t-bank"
                            variants={listChildrenVariant}
                            className='basis-1/3 '>
                            <div className='flex flex-col  '>
                                <div className='w-full h-full flex items-center md:justify-start justify-center'>
                                    <div className=' md:w-[180px] md:h-[180px] w-[150px] h-[150px] flex'>
                                        <img src={DirectToBank} className='w-full h-full object-contain' alt='direct-to-bank' />
                                    </div>
                                </div>

                                <p className='text-start text-lg font-extrabold text-blue'>
                                    Direct bank Deposits and Withdrawals
                                </p>
                                <p className='text-start my-2'>Eliminate 3rd party apps and vendors. Enjoy freedom and flexibility</p>


                            </div>
                        </motion.li>
                        <motion.li

                            variants={listChildrenVariant}
                            key="trade-anywhere" className='basis-1/3 '>
                            <div className='flex flex-col '>
                                <div className='w-full h-full flex items-center md:justify-start justify-center'>
                                    <div className=' md:w-[180px] md:h-[180px] w-[150px] h-[150px] flex'>
                                        <img src={TradeAnywhere} className='w-full h-full object-contain' alt='trade-anywhere' />
                                    </div>
                                </div>

                                <p className='text-start text-lg font-extrabold text-orange'>
                                    Trade Anytime, Anywhere
                                </p>
                                <p className='text-start my-2'>Kochure adapts to your lifestyle.use Kochure from any device.
                                    Expert Support: Our  team provides you with a premium support 24/7</p>
                            </div>
                        </motion.li>
                        <motion.li
                            variants={listChildrenVariant}
                            key="low-t-fee" className='basis-1/3 '>
                            <div className='flex flex-col items-start'>
                                <div className='w-full h-full flex md:justify-start justify-center'>
                                    <div className=' md:w-[180px] md:h-[180px] w-[150px] h-[150px] flex '>
                                        <img src={LowTradingFee} className='w-full h-full object-contain' alt='low-trading-fee' />
                                    </div>

                                </div>
                                <p className='text-start text-lg font-extrabold text-blue'>
                                    Enjoy Low Trading Fees
                                </p>
                                <p className='text-start my-2'>Kochure helps you tomaximise profits by giving you the most competitive rates in the market.</p>


                            </div>
                        </motion.li>
                    </motion.ul>
                </div>
                <div className='flex justify-center items-center my-10'>
                    <CtaButtons />
                </div>

            </Container>

        </div>
    )
}

export default Features