import React from "react";
import GooglePlay from "../assets/play_store.svg";
import AppleStore from "../assets/apple.svg";
import { motion } from "framer-motion";

export function CtaButtons() {
  return (
    <div className="flex md:space-x-10 space-x-0 md:space-y-0 space-y-5 md:flex-row flex-col">
      <a
        href="https://play.google.com/store/apps/details?id=com.kochure.kochureApp"
        target="_blank"
        rel="noreferrer"
        // onClick={(e) => {
        //   try {
        //     snaptr("track", "Download", {
        //       price: 0,
        //       currency: "INSERT_CURRENCY",
        //       item_ids: ["INSERT_ITEM_ID_1", "INSERT_ITEM_ID_2"],
        //       item_category: "INSERT_ITEM_CATEGORY",
        //       uuid_c1: "INSERT_UUID_C1",
        //       user_email: "INSERT_USER_EMAIL",
        //       user_phone_number: "INSERT_USER_PHONE_NUMBER",
        //       user_hashed_email: "INSERT_USER_HASHED_EMAIL",
        //       user_hashed_phone_number: "INSERT_USER_HASHED_PHONE_NUMBER",
        //     });
        //   } catch (err) {
        //     console.log("Track Failed");
        //   }
        // }}
      >
        <motion.div
          whileTap={{
            scale: 0.9,
          }}
          className="cursor-pointer flex items-center px-5 py-2 bg-black max-w-[180px] rounded-md shadow-lg"
        >
          <div>
            <img src={GooglePlay} alt="playstore" />
          </div>
          <div className="text-white pl-2 text-start">
            <p className="text-xs">Get on</p>
            <p className="md:text-[15px] text-[13px] font-bold">Google Play</p>
          </div>
        </motion.div>
      </a>
      <a
        href="https://apps.apple.com/ng/app/kochure/id1595574962"
        target="_blank"
        rel="noreferrer"
        // onClick={(e) => {
        //   try {
        //     snaptr("track", "Download", {
        //       price: 0,
        //       currency: "INSERT_CURRENCY",
        //       item_ids: ["INSERT_ITEM_ID_1", "INSERT_ITEM_ID_2"],
        //       item_category: "INSERT_ITEM_CATEGORY",
        //       uuid_c1: "INSERT_UUID_C1",
        //       user_email: "INSERT_USER_EMAIL",
        //       user_phone_number: "INSERT_USER_PHONE_NUMBER",
        //       user_hashed_email: "INSERT_USER_HASHED_EMAIL",
        //       user_hashed_phone_number: "INSERT_USER_HASHED_PHONE_NUMBER",
        //     });
        //   } catch (err) {
        //     console.log("Track Failed");
        //   }
        // }}
      >
        <motion.div
          whileTap={{
            scale: 0.9,
          }}
          className="cursor-pointer flex items-center px-5 py-2 bg-black max-w-[180px] rounded-md shadow-lg"
        >
          <div>
            <img src={AppleStore} alt="appstore" />
          </div>
          <div className="text-white pl-2 text-start">
            <p className="text-xs">Get on</p>
            <p className="md:text-[15px] text-[13px] font-bold">App Store</p>
          </div>
        </motion.div>
      </a>
    </div>
  );
}
