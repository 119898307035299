import React from 'react'
import Container from './Container'
import { ReactSVG } from 'react-svg'
import { CtaButtons } from './CtaButtons'
import Lightning from "../assets/lightning.svg"
import Simplicity from "../assets/simplicity.svg"
import CandleStick from "../assets/candle-stick.svg"
import Security from "../assets/security.svg"
import Transparency from "../assets/transparency.svg"
import { motion } from "framer-motion"

const Advantages = () => {
    const values = [{
        icon: Lightning,
        title: "Speed",
        desc: "Lightning-fast tradeswith Kochure's advanced technology"
    }, {
        icon: Simplicity,
        title: "Simplicity",
        desc: "Intuitive design makes trading as simple as ABC"
    },
    {
        icon: CandleStick,
        title: "Candlesticks Chart",
        desc: "Don’t trade blindly, see the market at a glance"
    }, {
        icon: Security,
        title: "Security",
        desc: "State-of-the-art Military grade encryption for peace of mind."
    },
    {
        icon: Transparency,
        title: "Transparency",
        desc: "Straightforward pricing without hidden fees"
    }
    ]
    const listParentVaraint = {
        "hide": {
            opacity: 0
        },
        "show": {
            opacity: 1,
            transition: {
                duration: 2,
                staggerChildren: 1,
                delay: 2,
            }
        },

    }
    return (
        <div className='w-full '>
            <Container >
                <p className='my-5 md:text-[30px] text-[20px] font-thin '>Stop being overwhelmed by other digital assets wallets out there</p>
                <p className='my-5 md:text-[30px] text-[20px] font-black '>Kochure is designed for your <span className='text-blue'>fast-paced</span> lifestyle</p>
                <motion.div
                    variants={listParentVaraint}
                    initial="hide" animate="show"

                    className='flex md:flex-row flex-col md:items-start items-center gap-10 my-10'>
                    <div>
                        <ul className='flex flex-col gap-10' >
                            {values.slice(0, 3).map(({ desc, icon, title }) => <li key={title} className='flex'>
                                <div className=''>
                                    <ReactSVG src={icon} className=' h-full object-contain' />
                                </div>
                                <div className='flex flex-col items-start mx-3'>
                                    <p className='text-start font-bold'>{title}</p>
                                    <p className='text-start text-sm'>{desc}</p>
                                </div>
                            </li>)}

                        </ul>
                    </div>
                    <div>
                        <ul className='flex flex-col gap-10'>
                            {values.slice(3).map(({ desc, icon, title }) => <li key={title} className='flex'>
                                <div className=''>
                                    <ReactSVG src={icon} className=' h-full object-contain' />
                                </div>
                                <div className='flex flex-col items-start mx-3'>
                                    <p className='text-start font-bold'>{title}</p>
                                    <p className='text-start text-sm'>{desc}</p>
                                </div>
                            </li>)}

                        </ul>

                    </div>

                </motion.div>
                <div className='flex justify-center items-center my-[50px]'>
                    <CtaButtons />
                </div>

            </Container>

        </div>
    )
}

export default Advantages