import { CtaButtons } from "./CtaButtons";
import React from "react";
import KochureLogo from "../assets/k_logo.svg";
import Container from "./Container";
import KochurePhone from "../assets/k_phone.png";
import { motion } from "framer-motion";

const Onboarding = () => {
  return (
    <div className="w-full md:h-[100vh] h-[120vh] bg-k_bg bg-center bg-cover overflow-hidden">
      <Container className="py-10">
        <div className="w-[150px]">
          <img src={KochureLogo} alt="Kochure Alt" />
        </div>
        <div className="flex md:flex-row flex-col my-[45px] md:h-[80vh] h-[100vh]">
          <section className="w-full h-full   text-start md:basis-1/2 basis-3/5">
            <div className="md:text-[60px] text-[35px] font-extrabold md:leading-[90px] leading-[40px]">
              <p className="text-blue">Kochure.</p>
              <p>
                Every Second <br /> Counts
              </p>
            </div>

            <div className=" text-lg">
              <p className="md:my-10 my-5">Ready to trade with speed and convenience?</p>
              <CtaButtons />
            </div>
          </section>
          <section className="md:basis-1/2 basis-2/5 overflow-hidden md:h-[90vh] ">
            <img
              src={KochurePhone}
              alt="phone"
              className="w-full h-full object-contain "
            />
          </section>
        </div>
        <div></div>
      </Container>
    </div>
  );
};

export default Onboarding;
