import React from 'react'


const Container = ({ children, className }) => {
    return (
        <div className={`${className}   xl:max-w-[85%] xl:px-0  mx-auto px-5`}>
            {children}
        </div>
    )
}

export default Container