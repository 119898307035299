import React from 'react'
import Container from './Container'
import { motion } from "framer-motion"

const Footer = () => {
    const listParentVaraint = {
        "hide": {
            opacity: 0,
            y: 100
        },
        "show": {
            opacity: 1,
            y: 0,
            transition: {
                duration: 2,
                staggerChildren: 1
            }
        },

    }
    return (
        <motion.div
            variants={listParentVaraint}
            initial="hide" animate="show"
            className='w-full md:h-[100vh] h-[60vh] md:bg-footer-lg bg-cover bg-no-repeat bg-footer-sm'>
            <Container className="relative">
                <div className='absolute w-[250px] md:w-[400px] md:top-[25vh] text-start text-white'>
                    <p className='text-[20px] md:text-[40px] font-extrabold my-[15px]'>Trade digital assets swiftly & seamlessly</p>
                    <p className='md:text-base text-sm'>Are you  missing lucrative trades due to slow transactions and dealing with complicated interfaces? Kochure offers a platform where you can manage your portfolio and trade over 50 + digital assets in Seconds, transparent pricing, and total control.</p>
                </div>
            </Container>
        </motion.div>
    )
}

export default Footer