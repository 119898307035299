
import './App.css';
import Advantages from './components/Advantages';
import Features from './components/Features';
import Footer from './components/Footer';
import Onboarding from './components/Onboarding';

function App() {
  return (
    <div className="App">
      <Onboarding />
      <Features />
      <Advantages />
      <Footer />
    </div>
  );
}

export default App;
